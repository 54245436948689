import Img from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"
import cardStyles from "src/styles/components/card.module.scss"
import cx from "classnames"
import styles from "src/styles/components/team-member.module.scss"

export default function TeamMember({ member, isPlain }) {
  const { photo, name, position } = member

  return (
    <div className={cx(!isPlain && cardStyles.cardContainer, styles.root)}>
      <div className={styles.photoContainer}>
        <Img alt="member" fluid={photo.childImageSharp.fluid} />
      </div>
      <h4 className={styles.title}>{name}</h4>
      <p className={styles.position}>{position}</p>
    </div>
  )
}

TeamMember.propTypes = {
  member: PropTypes.shape({
    photo: PropTypes.string,
    name: PropTypes.string,
    position: PropTypes.string,
  }),
  isPlain: PropTypes.bool,
}

TeamMember.defaultProps = {
  member: {},
}
