import React from "react"
import PropTypes from "prop-types"
import styles from "src/styles/components/tag.module.scss"
import { Link } from "gatsby"
import tagSlug from "src/helpers/tag-slug"

const Tag = ({ label }) => {
  return (
    <Link to={`/blog/tagged/${tagSlug(label)}`} className={styles.tag}>
      {label}
    </Link>
  )
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
}

Tag.defaultProps = {}

export default Tag
