import { graphql, useStaticQuery } from "gatsby"

import map from "lodash/map"

export default function useTeam() {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        sort: { order: ASC, fields: frontmatter___order }
        filter: { fileAbsolutePath: { regex: "/team/" } }
      ) {
        edges {
          node {
            id
            fileAbsolutePath
            frontmatter {
              photo {
                childImageSharp {
                  fluid(maxWidth: 450) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              is_active
              name
              position
            }
          }
        }
      }
    }
  `)
  return map(data.allMarkdownRemark.edges, "node")
}
