import { Box, Grid } from "@material-ui/core"
import { Link, graphql } from "gatsby"

import BlogPostPreview from "./blog-post-preview"
import Img from "gatsby-image"
import Layout from "./layout"
import Navbar from "./navbar"
import NewsletterSubscriptionForm from "./newsletter-subscription-form"
import PropTypes from "prop-types"
import React from "react"
import SEO from "./seo"
import Section from "./section"
import Tag from "./tag"
import TeamMember from "./team-member"
import _ from "lodash"
import blogJson from "content/data/static-pages/blog.json"
import cx from "classnames"
import info from "content/data/info.json"
import sectionStyles from "src/styles/components/section.module.scss"
import styles from "src/styles/components/blog-post.module.scss"
import useTeam from "src/static_queries/useTeam"

const getNextBlogPosts = (allBlogPosts, currentPost) => {
  const nextPosts = []
  const index = _.findIndex(
    allBlogPosts,
    post => post.node.fields.slug === currentPost.fields.slug
  )
  for (let i = 1; i <= 3; i += 1) {
    nextPosts.push(allBlogPosts[(index + i) % allBlogPosts.length])
  }
  return _.uniqBy(nextPosts, "node.fields.slug")
}

export default function Blog(props) {
  const { slug } = props.pageContext
  const allBlogData = props.data.allMarkdownRemark.edges
  const data = allBlogData.find(edge => edge.node.fields.slug === slug).node
  const nextBlogData = getNextBlogPosts(allBlogData, data)
  const team = useTeam()
  const {
    de: {
      min_read,
      all_posts,
      post: { footer_text, footer_description },
    },
  } = blogJson
  const {
    badge_google_play,
    badge_app_store,
    link_google_play,
    link_app_store,
  } = info

  const {
    excerpt,
    frontmatter: {
      author,
      title,
      date,
      hero_image,
      hero_image_link,
      hero_image_caption,
      tags,
      cta_link,
      cta_text,
    },
  } = data

  const authorData = _.find(team, ({ fileAbsolutePath }) =>
    fileAbsolutePath.endsWith(author)
  )

  return (
    <Layout>
      <Navbar />
      <SEO
        title={title}
        article
        description={excerpt}
        image={hero_image.childImageSharp.fluid.src}
      />
      <Grid container className={cx(sectionStyles.padded, styles.root)}>
        <Grid item sm={12} lg={9}>
          <Link to="/blog">{all_posts}</Link>
        </Grid>
        <br />
        <br />
        <Grid item sm={12} lg={9} className={styles.collapsible}>
          <span>{date}</span>
          {" • "}
          <span>
            {data.timeToRead} {min_read}
          </span>
          <h1 className={styles.title}>{title}</h1>
        </Grid>
        <Grid
          item
          justify="center"
          sm={12}
          lg={9}
          className={styles.collapsible}
        >
          <article>
            <figure className={styles.imageContainer}>
              <a href={hero_image_link}>
                <Img
                  className={styles.heroImage}
                  fluid={hero_image.childImageSharp.fluid}
                  alt={title}
                />
              </a>
              <figcaption>{hero_image_caption}</figcaption>
            </figure>
            <br />
            <div dangerouslySetInnerHTML={{ __html: data.html }} />
          </article>
          {cta_link && (
            <Grid item xs={12}>
              <Box mb={4}>
                <a href={cta_link}>
                  <button className={styles.ctaButton}>{cta_text}</button>
                </a>
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            {_.map(tags, t => (
              <Tag key={t} label={t} />
            ))}
          </Grid>
        </Grid>
        <div className={styles.mobileSpacer} />
        <Grid container justify="center" sm={12} lg={3}>
          <Box px={4} my="-0.5rem">
            <TeamMember showBio member={authorData.frontmatter} />
          </Box>
        </Grid>
      </Grid>
      <NewsletterSubscriptionForm
        type={_.includes(tags, "Business") ? "b2b" : "b2c"}
      />
      <Section
        section={{
          title: footer_text,
          description: footer_description,
        }}
      >
        <Grid container justify="center" className={styles.appStoreIcons}>
          <a target="_blank" rel="noopener noreferrer" href={link_app_store}>
            <img
              alt="App Store"
              className={styles.appStoreIcon}
              src={badge_app_store}
            />
          </a>
          <div className={styles.padding} />
          <a target="_blank" rel="noopener noreferrer" href={link_google_play}>
            <img
              alt="Play Store"
              className={styles.appStoreIcon}
              src={badge_google_play}
            />
          </a>
        </Grid>
      </Section>
      <Grid
        container
        className={cx(sectionStyles.root, sectionStyles.boundedWidth)}
        alignItems="flex-start"
        justify="flex-start"
        alignContent="flex-start"
      >
        {nextBlogData.map(post => (
          <BlogPostPreview
            key={post.node.fields.slug}
            post={post.node.frontmatter}
            slug={post.node.fields.slug}
            excerpt={post.node.excerpt}
          />
        ))}
      </Grid>
    </Layout>
  )
}

export const getPostData = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
      filter: {
        fileAbsolutePath: { regex: "/posts/" }
        frontmatter: { wip: { ne: true } }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM Do, YYYY")
            author
            title
            cta_text
            cta_link
            hero_image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            tags
          }
          html
          timeToRead
          excerpt(pruneLength: 200)
          fields {
            slug
          }
        }
      }
    }
  }
`
Blog.propTypes = {
  pageContext: PropTypes.shape({
    slug: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          frontmatter: PropTypes.shape({
            author: PropTypes.string,
            title: PropTypes.string,
            date: PropTypes.string,
            hero_image: PropTypes.string,
            hero_image_link: PropTypes.string,
            hero_image_caption: PropTypes.string,
          }),
          timeToRead: PropTypes.number,
          html: PropTypes.string,
          excerpt: PropTypes.string,
        })
      ),
    }),
  }),
}
